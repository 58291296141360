import { CrudModel, CrudModelType, ICrudModelType } from "../CrudModel";
import {
  CrudProperty,
  CrudPropertyQuery,
  ICrudProperty,
  ICrudPropertyGet,
} from "../CrudProperty";

import { cache } from "../api/cache";

export interface IRelationshipProperty extends ICrudProperty {
  relatedModel: ICrudModelType;
  apiDataProperty?: string;
  foreignProperty?: string;
  newModelDefaults?: Record<string, any> | Function;
}
export class RelationshipProperty extends CrudProperty {
  public relatedModel: CrudModelType;
  protected static _blankStaticInstances: CrudModel[] = [];
  protected getBlankInstance(): CrudModel {
    if (
      !(this._classRef as typeof RelationshipProperty)._blankStaticInstances[
        this.relatedModel.typeLabel
      ]
    )
      (this._classRef as typeof RelationshipProperty)._blankStaticInstances[
        this.relatedModel.typeLabel
      ] = new this.relatedModel();

    return (this._classRef as typeof RelationshipProperty)
      ._blankStaticInstances[this.relatedModel.typeLabel];
  }

  protected get isSetToBlankInstance() {
    return (
      this._modelInstance && ("" + this.modelInstance.id).indexOf("_") === 0
    );
  }

  protected _modelInstance?: CrudModel;
  public get modelInstance() {
    if (this._modelInstance) return this._modelInstance as CrudModel;

    if (!this._setValue) {
      this._modelInstance = new this.relatedModel();
    } else if (this._setValue instanceof this.relatedModel) {
      this._modelInstance = this._setValue;
    } else if (typeof this._setValue === "number") {
      this._modelInstance = cache.getFromCache(
        this.relatedModel,
        this._setValue
      );
      this.isModelHydrated = false;
    } else {
      this._modelInstance = cache.getFromCache(
        this.relatedModel,
        // @ts-ignore
        this._setValue.id,
        this._setValue
      );
    }

    return this._modelInstance as CrudModel;
  }

  public set modelInstance(val) {
    this._modelInstance = val;
  }

  public newModelDefaults?: Record<string, any> | Function;
  public createNew(opts: any = {}) {
    const relProps = {};
    if (this.model && this._foreignPropertyName) {
      relProps[this._foreignPropertyName] = this.model;
    }

    let newModelDefaults = {};
    if (typeof this.newModelDefaults === "function")
      newModelDefaults = this.newModelDefaults(this.model);
    else if (typeof this.newModelDefaults === "object")
      newModelDefaults = this.newModelDefaults;

    this.modelInstance = new this.relatedModel({
      ...relProps,
      ...opts,
      ...newModelDefaults,
    });
    return this.modelInstance;
  }

  protected _value: number | null = null;
  protected isModelHydrated = false;
  private _foreignPropertyName: string = "";
  public get foreignPropertyName(): string {
    if (!this._foreignPropertyName) {
      if (this.model)
        this._foreignPropertyName = this.model.getAsProperty(true);
      else
        console.error(
          "foreignPropertyName is not set on in RelationshipPropertyMany def:",
          this
        );
    }
    return this._foreignPropertyName;
  }

  constructor(opts: IRelationshipProperty, model: CrudModel) {
    super(opts, model);

    if (typeof opts.foreignProperty !== "undefined")
      this._foreignPropertyName = opts.foreignProperty; // TODO: refactor pluralization stuff in util func

    if (typeof opts.newModelDefaults !== "undefined")
      this.newModelDefaults = opts.newModelDefaults;

    this.relatedModel = CrudModel.getModelType(opts.relatedModel);
  }

  public get serializedName() {
    return this._serializedName ? this._serializedName : this.name;
  }

  public get typedName() {
    return this._typedName ? this._typedName : this.name;
  }

  public get serializedChangesName() {
    return this._serializedChangesName
      ? this._serializedChangesName
      : this.typedName;
  }

  public serializedValueGuarded(visited = new WeakSet<any>()): any {
    return this.hasUnsavedChanges ? this.serializedChangesValue : this.value;
  }

  public get serializedChangesValue() {
    return this.serializedChangesValueGuarded();
  }

  public serializedChangesValueGuarded(visited = new WeakSet<any>()): any {
    if (visited.has(this)) return null;
    visited.add(this);

    const modelWasReplaced =
      this._hasUnsavedChanges &&
      !this.modelInstance.hasUnsavedChanges &&
      !this.modelInstance.isNew;

    return modelWasReplaced
      ? this.value
      : [this.modelInstance.serializedChangesGuarded(visited)];
  }

  public get stringValue(): string {
    if (this.isModelHydrated) return this.getInstanceLabel();
    return "ID: " + this.value;
  }

  public get typedValue() {
    return this.typedValueGuarded() as CrudModel;
  }

  public typedValueGuarded(visited = new WeakSet<any>()): CrudModel | null {
    if (visited.has(this)) return null;
    visited.add(this);

    return this.modelInstance;
  }

  public get unsavedValue() {
    const modelWasReplaced =
      this._hasUnsavedChanges && !this.modelInstance.hasUnsavedChanges;
    return modelWasReplaced ? this.value : this.modelInstance.serializedChanges;
  }

  public get hasUnsavedChanges(): boolean {
    return this.hasUnsavedChangesGuarded();
  }

  public hasUnsavedChangesGuarded(visited = new WeakSet<any>()): boolean {
    if (visited.has(this)) return false;
    visited.add(this);

    return (
      this.isHydrated &&
      (this._hasUnsavedChanges ||
        this.modelInstance.hasUnsavedChangesGuarded(visited))
    );
  }

  public get isHydrated(): boolean {
    return this.isHydratedGuarded();
  }

  public isHydratedGuarded(visited = new WeakSet<any>()): boolean {
    if (visited.has(this)) return false;
    visited.add(this);

    return !!(
      this._modelInstance &&
      this.modelInstance.isPartiallyHydratedGuarded(visited)
    );
  }

  public takeSnapshot(snapshotId?: number) {
    snapshotId = super.takeSnapshot(snapshotId);
    if (!this.isHydrated) return snapshotId;

    this.typedValue.takeSnapshot(snapshotId);

    return snapshotId;
  }

  public restoreSnapshot(snapshotId?: number) {
    if (this.typedValue.hasSnapshot(snapshotId))
      this.typedValue.restoreSnapshot(snapshotId);
    else {
      super.restoreSnapshot(snapshotId);
      if (this.typedValue.isNew) this.typedValue.reset();
      else this.typedValue.hydrate();
    }
  }

  protected _setValue?: Record<string, any> | number | null;
  public set(val: number | CrudModel, skipMarkingAsUnsaved = false) {
    if (!val) {
      // if we're already null-ish, abort
      if (!this.value) return;

      this._value = null;
      this._setValue = null;
    } else {
      if (typeof val === "string") val = parseInt(val);
      if (typeof val === "number") {
        if (this.value == val) return;

        this._setValue = val;
        this._value = val;
      } else if (typeof val === "object") {
        this._setValue = val;
        this._value = val.id;

        // Kinda hacky bu it works.. more than just { id: 1 }
        this.isModelHydrated = Object.keys(val).length > 1;
      }
    }

    this._modelInstance = undefined;

    super.set(this._value, skipMarkingAsUnsaved);
  }

  public async hydrate(forceRefresh = false) {
    if ((this.isModelHydrated && !forceRefresh) || !this.value) return;

    return this.modelInstance.hydrate().then(() => {
      this.isModelHydrated = true;
    });
  }

  public getForeignRelationship() {
    const foreignRelationship = this.relatedModel.findProperty(
      this.foreignPropertyName
    );
    if (!foreignRelationship)
      throw new Error(
        `Foreign relationship not defined for property "${this.label}" on model "${this.model?.typeLabel}"`
      );
    return foreignRelationship;
  }

  public toPlainObject(
    opts: ICrudPropertyGet = {
      decorated: true,
      formatted: true,
    },
    model?: CrudModel
  ) {
    if (!opts.decorated && !opts.formatted) return this.value;

    // prevent recursion
    if (
      model &&
      model.typeLabel == this.modelInstance.typeLabel &&
      this.modelInstance.id === model.id
    )
      return {};

    return this.modelInstance.toPlainObject();
  }

  public getInstanceLabel() {
    return this.modelInstance.label;
  }

  private _stripRootProp(propString: string) {
    const propPieces = propString.split(".");
    propPieces.shift();
    return propPieces.join(".");
  }

  private _getRootProp(propString: string) {
    return propString.split(".")[0];
  }

  public findProperties(
    fieldArg: CrudPropertyQuery[] | CrudPropertyQuery
  ): CrudProperty[] | undefined {
    if (!Array.isArray(fieldArg)) fieldArg = [fieldArg];

    if (super.findProperties(fieldArg)) return [this as CrudProperty];

    if (
      fieldArg.some(
        (prop) => prop == this.serializedName || prop == this.typedName
      )
    )
      return [this as CrudProperty];

    const fieldArgsStringsWithoutRootProp = fieldArg
      .filter((arg) => typeof arg === "string")
      .filter(
        (stringArg) => this._getRootProp(stringArg as string) == this.name
      )
      .map((stringArg) => this._stripRootProp(stringArg as string))
      .filter((arg) => arg);

    if (fieldArgsStringsWithoutRootProp.length > 0) {
      const modelProperties = this.modelInstance.findProperties(
        fieldArgsStringsWithoutRootProp
      );
      return modelProperties;
    }

    return undefined;
  }

  public get isEmpty() {
    return this.value == null && !this.hasUnsavedChanges;
  }
}
