import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  LongTextField,
  RelationshipField,
  RelationshipFieldMany,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  JsonProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class PricedComponentMapping extends CrudModel {
  protected static _typeLabel = "Priced Component Mapping";
  protected static asProperty = "priced_component_mapping";
  public static api = {
    path: "priced-component-mappings",
  };
  protected static routeBase = "priced-component-mappings";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Order",
        name: "order",
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Comments",
        name: "comments",
      },
    },
    {
      type: JsonProperty,
      opts: {
        label: "Condtional",
        name: "conditional",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "spread_repeatable_step_type",
        relatedModel: "StepType",
        label: "Spread Repeatable Step Type",
        description:
          "If selected step type has multiple values, each will be priced individually as a new priced component for each value.",
      },
    },

    {
      type: RelationshipPropertyMany,
      opts: {
        name: "priced_component_mapping_steps",
        relatedModel: "PricedComponentMappingStep",
        label: "Priced Component Mapping Steps",
        foreignProperty: "priced_component_mapping",
        stringValueGlue: " ",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "pricing_schema",
        relatedModel: "PricingSchema",
        label: "PricingSchema",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: LongTextField,
      opts: {
        property: "comments",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "priced_component_mapping_steps",
        isPrimaryLabelField: true,
        viewComponentName: "priced-component-mapping-label",
        formComponentName: "form-relationship-field-many-table",
        requiresDetachedLabel: true,
        collectionLayoutOpts: {
          cols: 12,
          useDynamicFields: true,
        },
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "pricing_schema",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "spread_repeatable_step_type",
        fieldWidth: "full",
      },
    },
    {
      type: "ConditionalField",
      opts: {
        property: "conditional",
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
    },
    {
      id: "expansion",
      fieldSets: [
        {
          id: "0",
          fields: [
            "priced_component_mapping_steps",
            "spread_repeatable_step_type",
            "conditional",
            "comments",
          ],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      columnFields: ["priced_component_mapping_steps"],
      expansionComponent: {
        name: "ModelLayoutExpansion",
        opts: {
          layoutId: "expansion",
        },
      },
    },
  ];
}
