import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  FieldGroupField,
  NumberField,
  RelationshipField,
  SelectField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class MappingStepValue extends CrudModel {
  protected static _typeLabel = "Mapping Step Value";
  protected static asProperty = "mapping_step_value";
  public static api = {
    path: "mapping-step-values",
  };
  protected static routeBase = "mapping-step-values";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: { name: "id", userPermissions: UserPermission.Hidden },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: { label: "Value Type", name: "value_type", rules: ["required"] },
    },
    {
      type: StringProperty,
      opts: { label: "Constant Value", name: "constant" },
    },
    {
      type: RelationshipProperty,
      opts: { name: "step_type", relatedModel: "StepType", label: "Step Type" },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "consolidated_mapping_step",
        relatedModel: "ConsolidatedMappingStep",
        label: "Custom Mapping Step",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: { property: "created_at", userPermissions: UserPermission.Hidden },
    },
    {
      type: DateTimeField,
      opts: { property: "updated_at", userPermissions: UserPermission.Hidden },
    },
    {
      type: SelectField,
      opts: {
        property: "value_type",
        viewComponentName: "view-read-only",
        options: {
          step: "Step Type",
          consolidated: "Custom Mapping Step",
          constant: "Constant",
        },
      },
    },
    {
      type: FieldGroupField,
      opts: {
        id: "resolved_value",
        label: "Value",
        viewComponentName: "view-read-only",
        isPrimaryLabelField: true,
        seamless: true,
        fieldWidth: "medium",
        subFields: [
          {
            type: StringField,
            opts: {
              property: "constant",
              label: "",
              fieldWidth: "full",
              conditional: {
                property: "value_type",
                value: "constant",
              },
            },
          },
          {
            type: RelationshipField,
            opts: {
              property: "step_type",
              fieldWidth: "full",
              label: "",
              conditional: {
                property: "value_type",
                value: "step",
              },
            },
          },
          {
            type: RelationshipField,
            opts: {
              property: "consolidated_mapping_step",
              fieldWidth: "full",
              label: "",
              conditional: {
                property: "value_type",
                value: "consolidated",
              },
            },
          },
        ],
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "crud",
      fieldSets: [
        {
          id: "default",
          fields: ["value_type", "resolved_value"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: true,
      columnFields: ["value_type", "resolved_value"],
    },
  ];
}
