import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import { ModelLayoutField, NumberField } from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class PricedComponentMappingStep extends CrudModel {
  protected static _typeLabel = "Priced Component Mapping Step";
  protected static asProperty = "priced_component_mapping_step";
  public static api = {
    path: "priced-component-mapping-steps",
  };
  protected static routeBase = "priced-component-mapping-steps";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: NumberProperty,
      opts: {
        label: "Order",
        name: "order",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "priced_component_mapping",
        relatedModel: "PricedComponentMapping",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "mapping_step_value",
        relatedModel: "MappingStepValue",
        label: "Value",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: NumberField,
      opts: {
        property: "order",
      },
    },
    {
      type: ModelLayoutField,
      opts: {
        property: "mapping_step_value",
        isPrimaryLabelField: true,
        seamless: true,
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "expansion",
      fields: ["order", "mapping_step_value"],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: true,
      columnFields: [
        "mapping_step_value.value_type",
        "mapping_step_value.resolved_value",
      ],
      ordinalField: "order",
    },
  ];
}
