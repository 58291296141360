import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  DateTimeField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  DateTimeProperty,
  NumberProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class PricingSchema extends CrudModel {
  protected static _typeLabel = "Parsing Schema";
  protected static asProperty = "pricing_schema";
  public static api = {
    path: "pricing-schemas",
  };
  protected static routeBase = "pricing-schemas";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
    },
    UserPermission.Hidden
  );

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: StringProperty,
      opts: {
        label: "Name",
        name: "name",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "priced_component_mappings",
        relatedModel: "PricedComponentMapping",
        label: "Priced Component Mapping",
        foreignProperty: "pricing_schema",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "series",
        relatedModel: "Series",
        label: "Series",
        foreignProperty: "pricing_schema",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "dynamic_adders",
        relatedModel: "DynamicAdder",
        label: "Gold Adders",
        foreignProperty: "pricing_schema",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
      },
    },
    {
      type: StringField,
      opts: {
        property: "name",
        isPrimaryLabelField: true,
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "series",
        viewComponentName: "view-relationship-field-many-labels",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "dynamic_adders",
        viewComponentName: "view-relationship-field-many-labels",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "priced_component_mappings",
        viewComponentName: "view-relationship-field-many-labels",
        formComponentName: "form-relationship-field-many-table",
        requiresDetachedLabel: true,
        collectionLayoutOpts: {
          cols: 12,
        },
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["name", "series", "dynamic_adders"],
        },
        {
          id: "1",
          fields: ["priced_component_mappings"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["name", "series", "dynamic_adders"],
      filterFields: ["series"],
      enableSearch: true,
    },
  ];
}
