import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  BooleanField,
  DateTimeField,
  RelationshipField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";
import {
  BooleanProperty,
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

export class Series extends CrudModel {
  protected static _typeLabel = "Series";
  protected static asProperty = "series";
  public static api = {
    path: "series",
    fullCache: true,
  };
  protected static routeBase = "series";
  protected userIdField = [];

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  // public static modalEdit = false;
  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "name",
        label: "Name",
        isPrimaryLabelField: true,
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "identifier",
        label: "Identifier",
        rules: ["required"],
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_partial_series",
        label: "Partial Series",
        description:
          "Do part numbers with this prefix represent something aside from a standard series? e.g. loose contacts",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "pricing_schema",
        relatedModel: "PricingSchema",
        label: "Parsing Schema",
        description:
          "This is the schema used to parse part numbers of this series. If left blank, only direct matches in the price table will work for this series.",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "steps",
        relatedModel: "Step",
        label: "Steps",
        foreignProperty: "series",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: DateTimeField,
      opts: {
        property: "created_at",
        label: "Created At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeField,
      opts: {
        property: "updated_at",
        label: "Updated At",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringField,
      opts: {
        property: "name",
        isPrimaryLabelField: true,
        description: "The human-readable name of the series.",
      },
    },
    {
      type: StringField,
      opts: {
        property: "identifier",
        description: "This is how the series is identified in a part number.",
      },
    },
    {
      type: BooleanField,
      opts: {
        property: "is_partial_series",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "pricing_schema",
        label: "Parsing Schema",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "steps",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "seriesField",
        requiresDetachedLabel: true,
        collectionLayoutOpts: {
          cols: 12,
        },
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["name", "identifier", "pricing_schema", "is_partial_series"],
        },
        {
          id: "1",
          fields: ["steps"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      columnFields: ["name", "identifier"],
      filterFields: [],
      enableSearch: true,
      searchLabel: "Search by name or identifier",
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
  ];
}
